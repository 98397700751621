import { compose, setDisplayName } from 'recompose'

import LoginForm from '@components/Login'
import withUserAuth from '@hocs/withUserAuth'

const SignInPage = () => (
  <LoginForm />
)

export default compose(
  withUserAuth,
  setDisplayName('CmsSignInPage')
)(SignInPage)
