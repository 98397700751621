export const errorCodeMessages = (status, code) => {
  const error = {
    200: '',
    401: {
      0: 'Invalid username / password combination.',
      5: 'Your account is blocked because of too many failed login attempts.'
        + ' Please wait one minute before trying to access again.'
    },
    403: 'Not authorized to access.',
    500: 'Server error.',
    504: 'Gateway Timeout',
    required: 'Username and password are required.'
  }
  let errorObject = error[status]
  if (typeof errorObject === 'undefined') {
    errorObject = error['401']
  }
  if (typeof errorObject === 'string') {
    return errorObject
  }
  return typeof errorObject[code] === 'undefined' ? errorObject[0] : errorObject[code]
}

export default {}
